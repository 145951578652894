<template>
	<div class="bg-white px-3 pt-1" style="margin: -20px;margin-top: -1rem;margin-bottom: 0!important;">

		<el-table border class="mt-3" :data="tableData" style="width: 100%" @selection-change="handleSelectionChange" size="mini">
			<el-table-column align="center" prop="id" label="编号" width="80"></el-table-column>
			<el-table-column align="center" label="类型">
				<template slot-scope="scope">
					<div v-if="scope.row.lx == 1" style="background: #03c200;color: #ffffff;">实物</div>
					<div v-else-if="scope.row.lx == 2" style="background: #d50003;color: #ffffff;">优惠券</div>
					<div v-else style="background: #f58700;color: #ffffff;">积分</div>
				</template>
			</el-table-column>
			<el-table-column align="center" label="奖品">
				<template slot-scope="scope">
					<div v-if="scope.row.lx == 1">{{scope.row.lxid.title}}*1杯</div>
					<div v-else-if="scope.row.lx == 2">{{scope.row.lxid.name}}*1张</div>
					<div v-else>{{scope.row.lxid}}积分</div>
				</template>
			</el-table-column>
			<el-table-column align="center" label="中奖用户">
				<template slot-scope="scope">
					{{scope.row.user_id.nickname}}
				</template>
			</el-table-column>
			<el-table-column align="center" label="用户id">
				<template slot-scope="scope">
					{{scope.row.user_id.id}}
				</template>
			</el-table-column>
			<el-table-column align="center" label="使用状态">
				<template slot-scope="scope">
					<div v-if="scope.row.syzt == 0" style="background: #09b400;color: #ffffff;">待使用</div>
					<div v-else style="background: #de0000;color: #ffffff;">已使用</div>
				</template>
			</el-table-column>
		</el-table>
		<div style="height: 60px;"></div>
		<el-footer class="border-top d-flex align-items-center px-0 position-fixed bg-white" style="bottom: 0;left: 200px;right: 0;z-index: 100;">
			<div style="flex: 1;" class="px-2">
				<el-pagination
					:current-page="page.current"
					:page-sizes="page.sizes"
					:page-size="page.size"
					layout="total, sizes, prev, pager, next, jumper"
					:total="page.total"
			 	@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
				></el-pagination>
			</div>
		</el-footer>
	</div>
</template>

<script>
import common from '@/common/mixins/common.js'; // 引入 全局共公样式
import { mapState } from 'vuex'; // 引入 全局 公共数据
export default {

	mixins: [common], // 引入mixins里的common.js
	inject: ['app','layout'], // 注入layout.vue
	filters: {
		getStatus(row) {
			return formatStatus(row);
		}
	},
	data() {
		return {
			preUrl: 'cjjl',

			tableData: [],

			createModel: false,
			editIndex: -1,
		};
	},
	computed: {
		...mapState({
			user: state => state.user.user,
		})
	},
	methods: {
		getListUrl() {
			return `/admin/${this.preUrl}/${this.page.current}?limit=${this.page.size}`;
		},
		getListResult(e) {
			this.tableData = e.list;
		},
		// getlist(){
		// 	this.axios.get('/admin/cjjl/1', {
		// 		token: true
		// 	}).then(res => {
		// 		this.tableData = res.data.data.list
		// 		console.log(this.tableData)
		// 	})
		// },
		// 打开模态框
		openModel(e = false) {
			const r = e.row;
			this.form = {
				name: r.name,
				sycj: r.sycj,
				num: r.num,
				syzt:r.syzt,
				id:r.id
			};
			this.editIndex = e.$index;
			// 打开dialog
			this.createModel = true;
		},
		// 添加规格
		submit() {
			this.axios.post('/admin/jfsz/'+this.form.id, this.form, {
				token: true
			}).then(res => {
				this.$message({
					type: "success",
					message: "操作成功"
				})
				this.getlist()
			})
			// 关闭模态框
			this.createModel = false;
		}
	}
};
</script>

<style>
.sku-list-item > i {
	display: none;
	cursor: pointer;
}
.sku-list-item:hover {
	background-color: #f4f4f4;
}
.sku-list-item:hover > font {
	display: none;
}
.sku-list-item:hover > i {
	display: inline-block;
}
.el-form-item{
	margin-bottom: 0;
}
</style>
